.enkrateia-table {
  margin-top: 20px;
  width: 100%;
  border-collapse: collapse;

  .cursor-pointer {
    cursor: pointer;
  }

  .select-none {
    user-select: none;
  }

  .left {
    text-align: left;
  }

  .center {
    text-align: center;
  }

  .sort-indicator {
    font-weight: 400;
    font-size: 16px;

    &.unsorted {
      opacity: 0;
    }
  }

  .sortable-column:hover .sort-indicator.unsorted {
    opacity: 0.7;
  }

  & > thead {
    th {
      font-family: 'Hanuman', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;

      padding: 10px 20px 5px 20px;
      color: #4A4A4A;

      text-transform: uppercase;
      white-space: nowrap;
      text-align: left;
    }
  }

  &.row-clickable > tbody tr:not(.expanded-content) {
    cursor: pointer;

    &:hover {
      background: #f2f2f2;
    }
  }

  & > tbody tr:not(.expanded-content) td {
    white-space: nowrap;
  }

  & >tbody tr.expanded-content td {
    cursor: default;
  }

  & > tbody tr td {
    .td-inner-wrapper {
      display: flex;
      position: relative;
      padding: 25px 20px 20px 20px;

      font-family: 'Hanuman', sans-serif;
      font-weight: 400;
      font-size: 18px;
      line-height: normal;
      color: black;
    }

    a.td-inner-wrapper {
      text-decoration: none;
    }


    border-collapse: collapse;

    border-top: 1px solid #D8D8D8;
    border-bottom: 1px solid #D8D8D8;

    position: relative;

    &:first-child {
      border-left: 1px solid #D8D8D8;
    }

    &:last-child {
      border-right: 1px solid #D8D8D8;
    }

    &.description {
      width: min(35vw, 600px)
    }
  }

  .loader-row {
    padding: 100px;
  }

  &.variant-no-border {
    td {

      vertical-align: top;
      border-bottom: none !important;
      border-left: none !important;
      border-right: none !important;

      .td-inner-wrapper {
        color: #4A4A4A;
        font-family: Hanuman, sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        padding: 2px 5px 2px 5px;
        white-space: wrap;
      }
    }

    th {
      padding: 2px 5px 2px 5px;
    }
  }
}
