@import "src/styles/colors";

.enkrateia-collapsible-section {
  button.header {
    padding: 5px 10px;
    border-radius: 10px;
    border: 1px solid $primaryBlue100;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    background: #FFF;
    color: $primaryBlue100;

    transition: color ease-in-out 200ms, background ease-in-out 200ms;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);

    div {
      margin-top: 5px;
    }

    svg {
      margin-right: 20px;
      fill: $primaryBlue100;
      transition: transform ease-in-out 200ms;
    }

    font-family: Hanuman, sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

    cursor: pointer;
    width: 100%;


    &.open {
      background: $primaryBlue100;
      color: #FFF;

      svg {
        fill: #FFF
      }

      svg {
        transform: rotate(90deg);
        transform-origin: center;
      }
    }

    &.open:hover {
      background: $primaryBlue80;
    }

    &.closed:hover {
      background: #f5f5f5;
    }
  }
}
