.enkrateia-proponent-scoring-and-feedback {
  .title {
    margin-top: 20px;

    color: #4A4A4A;

    font-family: 'Hanuman', sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 17px; /* 77.273% */
  }

  .feedback {
    margin-left: 20px;
  }
}
