.enkrateia-new-assessment-modal {
  .pages {
    min-width: 580px;
    max-width: 580px;
    margin: 10px 0;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & > div {
      width: 100%;
    }
  }

  .form-elements {
    width: 100%;
    padding: 15px;
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-column-gap: 8px;
    grid-row-gap: 8px;

    justify-content: flex-start;
    align-items: flex-start;

    label {
      margin-top: 5px;

      display: flex;
      flex-direction: row;
      align-items: center;

      svg {
        margin-left: 10px;
      }
    }
  }

  .submit-container {
    display: flex;
    justify-content: center;
  }

  .success-step {
    .submit-container {
      margin-top: 20px;
      margin-bottom: 30px;

      button {}
    }
  }

  .disclaimer {
    padding: 10px;

    textarea {
      resize: none;
    }
  }

  .info.bottom {
    text-align: center;
  }
}
