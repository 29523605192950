.enkrateia-mailto-icon-link {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background: rgba(0,0,0,0.1);
  }
}
