@import 'src/styles/colors';

.enkrateia-arch-list-switch {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  align-content: flex-start;
  button {
    background: none;
    outline: none;
    flex: 1;

    font-family: 'Hanuman', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;

    cursor: pointer;

    border: 1px solid $primaryBlue100;
    padding: 8px 25px 5px 25px;

    transition: background-color 150ms ease-in-out, color 150ms ease-in-out;

    &:first-of-type {
      border-radius: 10px 0 0 10px;
    }

    &:last-of-type {
      border-radius: 0 10px 10px 0;
    }

    &.active {
      color: white;
      background: $primaryBlue100;
    }

    &:not(.active) {
      color: $primaryBlue100;
      background: white;

      &:hover {
        color: white;
        background: $primaryBlue100;
      }
    }
  }
}
