.enkrateia-project-condensed-overview-page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  max-height: 100vh;
  overflow: hidden;

  .content {
    flex: 1;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: auto;
    padding: 28px 62px;
    gap: 20px
  }
}
