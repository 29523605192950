.enkrateia-start-review-process {
  flex-direction: row !important;

  svg {
    margin-right: 10px;
  }
}

.enkrateia-start-review-modal-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  gap: 16px;
  padding: 16px 48px;

  button {
    width: 200px;
  }

  &.success, &.error {
    p {
      margin: 0;
      max-width: 500px;
    }

    button {
      width: 100px;
    }
  }

  &.error p {
    color: #AC1C1C;
  }
}

.enkrateia-start-review-modal-content .enkrateia-kliparo-modal {
  min-width: 320px;
}
