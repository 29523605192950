$primaryBlue100: #05445E;
$primaryBlue80: #115675;
$primaryBlue50: #2e81a2;
$primaryBlue30: #5d9fb9;

$secondaryBlue100: #013549;
$secondaryBlue90: #104f69;
$secondaryBlue80: #225f79;
$secondaryBlue50: #37748d;
$secondaryBlue30: #5d94ab;

$green100: #5abc34;
$green80: #83cb71;
$green50: #b0dd9f;
$green30: #d1ecc3;

$darkGreen100: #326645;
$darkGreen80: #467636;

$orange100: #f57215;
$orange80: #f78a35;
$orange50: #fab274;
$orange30: #fcd0a6;

$grey100: #788096;
$grey80: #8f94a6;
$grey50: #b5b6c1;
$grey40: #D8DADF;
$grey30: #d2d3d9;

$yellow100: #F8C925;
$yellow80: #F9D146;
$yellow50: #FCE492;
$yellow30: #FEF9E5;

$primaryContrastText: #000;

$traroInteractiveElement: #05445E;
$infoEmphasis: #000000;
$infoBackground: #DEF3FC;

$openProject: #05445E;
$inReviewProject: #9B3D13;
$finalProject: #9D7ACB;

$accentLight: #ADCFDE;
$errorTextColor: #d30000;

$traroBrick: #DDB483;
$traroBrickTextFilled: #000;
$traroBrickTextEmpty: #fff;

$traroVoussoir: #9A8384;
$traroSpringer: #D99F50;
$traroKeystone: #B98765;

$scoringRed: #B63333;
$scoringYellow: #D99F50;
$scoringGreen: #67B54C;
$scoringOrange: #F38230;

/* Traro colors */
$traroSecondary500: #05445E;
$traroTertiary500: #97EEFF;
