@import "src/styles/colors";

.enkrateia-panel {
  display: flex;
  padding: 15px 10px;
  flex-direction: column;
  align-items: stretch;
  gap: 20px;

  border-radius: 8px;
  border: 2px solid $primaryBlue100;
}
