.enkrateia-reviewer-scoring-and-feedback {
  position: relative;

  .enkrateia-auto-save-indicator {
    position: sticky;
    top: -24px;
    background: white;
    z-index: 10;
    float: right;
    margin-right: 10px;
  }
}
