.enkrateia-element-response-tickets {
  h2 {
    color: #05445E;

    font-family: Hanuman, sans-serif;
    font-size: 22px;
    margin: 0;
  }

  button {
    width: 100%;

    svg {
      margin-right: 10px;
    }
  }
}
