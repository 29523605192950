.enkrateia-condensed-arch-element-overview {
  width: 100%;

  & > section {
    padding: 20px 0;
    gap: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .enkrateia-panel {
    padding: 2px 10px;

    & > h3 {
      color: #05445E;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      margin: 0;
      padding-top: 2px;
    }
  }

  .score {
    display: flex;
    flex-direction: row;
    align-items: center;

    span {
      padding-top: 2px;
      font-size: 18px;
    }
  }

  .row {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 20px;

    & > * {
      width: 50%;
      display: flex;
      flex-direction: column;
      gap: 0;
    }
  }

  .enkrateia-kliparo-question-response {
    padding: 0;
  }

  .scores-and-feedback .enkrateia-table .td-inner-wrapper {
    :first-of-type {
      margin-top: 0;
    }

    :last-of-type {
      margin-bottom: 0;
    }
  }
}
