@import "src/styles/colors";

.enkrateia-reset-password-page {
  form {
    width: 100%;
    display: flex;
    flex-direction: column;


    h3 {
      font-size: 16px;
      color: $primaryBlue100;
      margin-bottom: 4px;
    }

    p {
      margin-top: 0;
    }

    .enkrateia-text-input {
      margin-top: 40px;
    }

    button {
      margin: 30px 15px;
    }
  }

  a {
    margin-top: 30px;
  }

  .success {
    display: flex;
    flex-direction: column;
    align-items: center;

    margin-top: 30px;

    h3 {
      color: green;
    }

    p {
      text-align: center;
    }

    a {
      margin-top: 15px;
    }
  }
}
