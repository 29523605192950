@import "src/styles/colors";


.enkrateia-ticket-category {
  .enkrateia-ticket-comment .ticket-content {
    min-width: auto;
  }

  .comment-form button {
    white-space: nowrap;
  }

  tr.expanded {
    background: #EAEAEA;
  }

  textarea {
    min-height: 100px;
  }
}
