.enkrateia-traro-export-responses {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 15px 30px 10px;

  button {
    width: 100%;

    svg {
      margin-right: 10px;
    }
  }
}
