.enkrateia-condensed-project-information {
  dl {
    display: grid;
    grid-template-columns: auto 1fr;
    column-gap: 20px;
    row-gap: 5px;

    dt,
    dd {
      margin: 0;
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    dt {
      font-weight: bold;
      color: #4A4A4A;
    }

    dt::after {
      content: ":";
    }
  }

  .enkrateia-rfp-response-status-indicator > span {
    color: #808080;

    &.active {
      color: black;
      font-weight: bold;
    }
  }
}
