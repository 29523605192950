.enkrateia-response-tickets {
  &.empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h3 {
      margin-top: 60px;
      margin-bottom: 0;
    }

    p {
      margin-top: 5px;
      margin-bottom: 15px;
    }
  }
}
