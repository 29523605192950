.enkrateia-ticket-detail-page {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 100vh;

  main {
    flex: 1;
    display: flex;
    flex-direction: column;

    overflow: auto;
  }

  .max-width {
    max-width: 950px;
  }

  .enkrateia-footer {
    position: sticky;
    bottom: 0;
    width: 100%;

    display: flex;
    justify-content: center;

    padding: 10px;
  }
}
