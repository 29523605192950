@import "src/styles/colors";
@import "src/styles/fonts";

.enkrateia-rich-textarea-input {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;

  & > * {
    min-width: 100px;
    width: 100%;
  }

  &.full-width > *, &.full-width input {
    width: 100%;
  }

  label {
    display: flex;
    align-items: center;

    .rc-tooltip-trigger-wrapper {
      display: flex;
      align-items: center;
      margin-left: 10px;
    }
  }

  &.variant-standard {
    label {
      margin-bottom: 7px;
      text-transform: uppercase;
      font-family: 'Hanuman', serif;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 14px;
      color: $primaryBlue100;
    }

    textarea {
      border: 0;
      outline: 0;
      background: transparent;
      border-bottom: 2px solid $accentLight;
      padding: 6px 0;
      font-size: 12pt;
      font-family: 'Montserrat', serif;
    }
  }


  &.variant-filled {
    label {
      margin-bottom: 7px;

      font-family: 'Hanuman', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      color: #000;
    }

    textarea {
      outline: 0;
      padding: 8px 6px 4px 6px;
      font-size: 12pt;
      font-family: 'Montserrat', serif;

      background: #FFFFFF;
      border: 1px solid #D0D4D9;
    }
  }

  .error-label {
    color: $errorTextColor;
  }

  .character-count {
    text-align: right;
    color: $grey80;
  }

  &.error .character-count {
    color: $errorTextColor;
  }

  .quill-container {
    display: flex;

    .quill {
      flex: 1;
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    .ql-editor.ql-blank::before {
      font-family: 'Montserrat', serif;
    }


    .ql-editor * {
      font-family: 'Montserrat', serif;

      em {
        font-style: italic;
      }
    }

    &.disabled {
      button, .ql-picker-label {
        opacity: 0.5;
        cursor: default;
        pointer-events: none;
      }

      .ql-editor {
        background: hsl(0deg, 0%, 95%);
      }
    }
  }

  &.auto-height {
    .quill-container {
      height: auto;
      min-height: 100%;

      .ql-editor {
        overflow-y: visible;
      }
    }
  }
}
