@import "src/styles/colors";

.enkrateia-kliparo-project-overview {
  width: 100%;
  min-height: 100%;
  position: relative;

  .enkrateia-announcement {
    background-color: #FFF2C5;
    border: 1px solid #000000;
    border-radius: 6px;

    padding: 20px 30px;
    margin: 20px 0;

    h2, p {
      margin: 0;
    }

    p {
      margin-top: 10px;
    }
  }

  .head {
    font-family: 'Hanuman', sans-serif;
    font-style: normal;
    padding: 30px;
    margin-right: 45px;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;

    width: 100%;
    max-width: 1500px;

    .title {
      flex: 1;
      margin-right: 20px;

      .back-to-dashboard {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 5px;
        text-decoration: underline;

        color: $primaryBlue100;

        svg {
          margin-bottom: 3px;
          margin-right: 10px;
        }
      }
      h1 {
        font-weight: 700;
        font-size: 22px;
        line-height: 32px;
        margin: 0;
      }

      a {
        font-weight: 400;
        line-height: 21px;
        display: inline-flex;
        text-decoration: none;

        img {
          margin-bottom: 1px;
          margin-right: 3px;
          width: 21px;
          height: 21px;
        }
      }
    }

    .head-content {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .actions {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        button {
          white-space: nowrap;
        }

        button.variant-link {
          svg {
            margin-right: 10px;
            margin-bottom: 2px;
          }
        }
      }
    }
  }

  .body {
    margin-top: 30px;
  }
}
