.enkrateia-ticket-details {
  .header {
    border-bottom: 1px solid #DEDEDE;
    padding: 17px 45px 5px 30px;

    h2 {
      margin: 0;
    }

    & > div {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }
}
