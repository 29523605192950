@import "src/styles/colors";

.enkrateia-question-status-button {
  display: flex;
  align-items: center;
  white-space: nowrap;

  svg {
    margin-right: 5px;
    width: 25px;
    height: 25px;
  }

  span {
    margin-top: 3px;
  }

  &.enkrateia-button {
    border: none !important;

    &.begin {
      color: #000;
      $bgColor: $yellow100;

      background-color: $bgColor;

      &:not(:disabled):not(.readonly) {
        &:hover {
          background-color: darken($bgColor, 15%);
        }

        &:active {
          background-color: darken($bgColor, 30%);
        }
      }
    }

    &.incomplete {
      color: #fff;
      $bgColor: #B63333;

      background-color: $bgColor;

      &:not(:disabled):not(.readonly) {
        &:hover {
          background-color: darken($bgColor, 5%) !important;
        }

        &:active {
          background-color: darken($bgColor, 10%) !important;
        }
      }
    }

    &.done {
      color: #fff;
      $bgColor: #014F71;

      background-color: $bgColor;

      &:not(:disabled):not(.readonly) {
        &:hover {
          background-color: darken($bgColor, 5%) !important;
        }

        &:active {
          background-color: darken($bgColor, 10%) !important;
        }
      }
    }

    &.review-only {
      color: #000;
      $bgColor: #ADCFDE;

      background-color: $bgColor;

      &:not(:disabled):not(.readonly) {
        &:hover {
          background-color: darken($bgColor, 5%) !important;
        }

        &:active {
          background-color: darken($bgColor, 10%) !important;
        }
      }
    }

    &.update-required {
      color: #fff;
      $bgColor: #F26728;

      background-color: $bgColor;

      &:not(:disabled):not(.readonly) {
        &:hover {
          background-color: darken($bgColor, 5%) !important;
        }

        &:active {
          background-color: darken($bgColor, 10%) !important;
        }
      }
    }

    &.review-complete {
      color: #fff;
      $bgColor: #014F71;

      background-color: $bgColor;

      &:not(:disabled):not(.readonly) {
        &:hover {
          background-color: darken($bgColor, 5%) !important;
        }

        &:active {
          background-color: darken($bgColor, 10%) !important;
        }
      }
    }
  }
}
