.enkrateia-reviewer-scoring-and-feedback-response-cell {
  width: 100%;
  padding-bottom: 20px;

  .investigative-question {
    & > :first-child {
      margin-top: 0;
    }

    & > :last-child {
      margin-bottom: 0;
    }
  }


  .enkrateia-rich-textarea-input {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .enkrateia-slider label {
    font-size: 14px;
    text-transform: uppercase;
    color: #4A4A4A;
  }
}
