@import 'src/styles/colors';

.enkrateia-rfp-response-actions {
  h2 {
    color: $primaryBlue100;
    margin: 0 0 10px 0;
  }

  .enkrateia-open-condensed-view {
    flex-direction: row !important;

    svg {
      margin-right: 10px;
      margin-bottom: 5px;
    }
  }

  .actions-row {
    display: flex;
    flex-direction: row;
    gap: 20px;
  }
}
