.enkrateia-register {
  .background {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    background-repeat: no-repeat;
    background-size: cover;
    background-position: left center;

    z-index: -1;

    &:after {
      content: '';
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

      background: linear-gradient(90deg, rgba(255, 255, 255, 1) 20%, rgba(255, 255, 255, 0.3) 100%);
    }

  }

  .enkrateia-header {
    background: white;
  }

  main {
    display: flex;
    flex: 1;
    flex-direction: column;

    padding: 16px 65px;

    h1, h2, p {
      margin: 0;
    }

    section {
      margin-top: 15px;
      margin-bottom: 15px;

      .row {
        display: flex;
        flex-direction: row;

        .col {
          min-width: 460px;
          display: flex;
          flex-direction: column;

          .enkrateia-text-input input,
          .enkrateia-textarea-input textarea,
          .enkrateia-select-input .enkrateia-react-select-container {
            min-width: 300px;
          }

          .enkrateia-textarea-input textarea {
            max-width: 480px;
            width: 100%;
          }

          .enkrateia-textarea-input {
            flex: 1;
          }
        }
      }
    }

    section .row.submit {
      align-items: center;

      a {
        margin-left: 30px
      }
    }

    .enkrateia-text-input,
    .enkrateia-textarea-input,
    .enkrateia-select-input {
      padding-top: 30px;

      label {
        display: flex;
        flex-direction: row;
      }

      label + div {
        display: flex;
        flex-direction: column;
      }
    }

    .enkrateia-vault-checkbox-input label {
      font-size: 18px;
    }

    .terms label a {
      color: #000 !important;
    }
  }
}
