.enkrateia-arch-text {
  fill: white;

  font-family: 'Hanuman', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 23px;
  text-align: center;

  p {
    margin: 0;
    color: white;
    white-space: normal;
    line-height: 18px;

    &.start {
      text-align: left;
    }

    &.end {
      text-align: right;
    }
  }
}
