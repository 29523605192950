.enkrateia-go-back-panel {
  height: 70px;
  display: flex;
  align-items: center;

  a {
    padding: 20px 30px;
    display: inline-flex;
    align-items: center;
    font-size: 16px;

    svg {
      margin-right: 5px;
    }
  }

  &.variant-sidebar {
    border-bottom: 1px solid #306780;

    a {
      color: white;
    }
  }

  &.variant-panel {
    a {
      color: black;
    }
  }
}
