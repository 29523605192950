@import "src/styles/colors";

.enkrateia-element-page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  max-height: 100vh;
  overflow: hidden;

  .content {
    flex: 1;
    display: flex;
    flex-direction: row;
    position: relative;
    overflow: hidden;
    min-height: 120px;
    position: relative;

    main {
      width: 100%;
      position: relative;
      overflow-y: auto;
      display: flex;

      @media (max-width: 1600px) {
        display: block;
      }

      & > div {
        min-width: calc(50% - 30px);
        flex: 1;
        overflow: auto;
        position: relative;
      }

      .enkrateia-arch-bricks-side-panel {
        max-height: 100%;
        overflow: hidden;
      }
    }
  }

  .enkrateia-tabs-tab > .enkrateia-element-context {
    max-width: 750px;
  }

  .enkrateia-tabs .enkrateia-tabs-header .enkrateia-tab-name {
    padding: 10px;
  }

  .enkrateia-tabs-header {
    max-width: 700px;

    .enkrateia-create-ticket-button {
      margin-left: 200px;
    }
  }

  .enkrateia-sidebar .enkrateia-question-status-button {
    margin: 10px;

    &.done {
      border: 1px solid $accentLight !important;
    }
  }

  .right-side-bar {
    padding: 25px;
    border-left: 1px solid #DEDEDE;
    gap: 20px;
    display: flex;
    flex-direction: column;
  }
}
