.enkrateia-kliparo-dashboard {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;

  .enkrateia-header {
    width: 100%;
  }

  .content {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    .actions {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      align-items: center;
      width: 100%;
      margin-bottom: 18px;
      grid-column-gap: 10px;

      padding: 32px 0 20px 0;

      button, .enkrateia-link-button {
        height: 70px;
        display: flex;
        justify-content: center;
        align-items: center;
        white-space: nowrap;
        flex-direction: column;

        .link {
          text-decoration: underline;
        }

        .enkrateia-svg-icon {
          margin-bottom: -4px;
          margin-right: 10px;
        }

        .subtext {
          width: 100%;

          text {
            fill: white;
          }
        }
      }
    }

    & > * {
      max-width: 940px;
    }

    & > .divider {
      width: 100%;
      border-bottom: 1px solid #DEDEDE;
    }
  }

  .enkrateia-footer {
    padding: 16px;
    background: white;
    width: 100%;
    justify-content: center;
    display: flex;
  }

  .enkrateia-dashboard-recent-tickets {
    margin-top: 30px;
  }
}
