.enkrateia-organization-profile-details {
  h2 {
    margin-top: 0;
  }

  .details {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-column-gap: 24px;
    grid-row-gap: 8px;
    max-width: 950px;

    justify-content: flex-start;
    align-items: flex-start;


    font-weight: 400;
    font-size: 20px;

    .value {
      color: #4A4A4A;
    }

    span {
      display: flex;
      flex-direction: column;
    }
  }

  .help-row {
    margin-top: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #05445E;
    font-size: 16px;

    svg {
      margin-top: -2px;
      margin-right: 8px;
    }

    a {
      margin-left: 5px;
      color: #05445E;
    }
  }
}
