@import "src/styles/colors";
@import "src/styles/fonts";

.enkrateia-slider {
  padding: 35px 10px 40px 0;
  display: flex;
  flex-direction: row;

  label {
    display: flex;
    align-items: center;
    margin-right: 15px;
    margin-top: -2px;

    .rc-tooltip-trigger-wrapper {
      display: flex;
      align-items: center;
      margin-left: 10px;
    }
  }

  &.variant-standard {
    label {
      margin-bottom: 7px;
      text-transform: uppercase;
      font-family: 'Hanuman', serif;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 14px;
      color: $primaryBlue100;
    }
  }

  &.variant-filled {
    label {
      margin-bottom: 7px;

      font-family: 'Hanuman', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      color: #000;
    }
  }

  .rc-slider-rail {
    background: #C1C9D2;
    height: 4px;
  }

  .rc-slider-track {
    background: #05445E;
  }

  .rc-slider-handle {
    width: 20px;
    height: 20px;
    background: #fff;
    filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.12)) drop-shadow(0px 2px 5px rgba(60, 66, 87, 0.08));
    margin-top: -8px;
    opacity: 1;
    border: 1px solid #C1C9D2;

    &.rc-slider-handle-dragging {
      border: 2px solid #05445E;
      box-shadow: none;
      filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.7)) drop-shadow(0px 2px 5px rgba(60, 66, 87, 0.1));
    }
  }

  .rc-slider-dot {
    opacity: 1;
    border: 1px solid #C1C9D2;

    &.rc-slider-dot-active {
      border: 2px solid #05445E;
    }
  }

  .rc-slider-mark-text {
    margin-top: 14px;

    &:before {
      position: absolute;
      content: '';
      width: 1px;
      height: 6px;
      background: #858b8d;
      top: -10px;
      left: calc(50% + -0.5px);
    }

    &:first-of-type, &:last-of-type {
      margin-top: 18px;

      &:before {
        top: -14px;
        height: 10px;
      }
    }
  }

  .rc-slider-handle {
    .static-tooltip {
      position: absolute;
      top: -24px;

      font-size: 12px;

      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      line-height: 120%;

      width: 150px;
      right: 20px;
      transition: width ease-in-out 100ms;

      .static-tooltip-content {
        border-radius: 6px 6px 0 6px;
        padding: 4px 10px 1px 10px;
        background: #d2d3d9;
        color: #05445E;
        border: 1px solid #d2d3d9;

        max-height: 20px;
        height: 20px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }


      &:hover {
        width: 200px;

        .static-tooltip-content {
          height: auto;
          max-height: initial;
          white-space: wrap;
        }
      }
    }
  }


  .rc-slider-disabled {
    background-color: transparent;

    .rc-slider-track {
      background: #71767c;
    }

    .rc-slider-dot.rc-slider-dot-active {
      border: 2px solid #71767c;
    }

    .rc-slider-handle .static-tooltip .static-tooltip-content {
      color: #44474b;
    }
  }

  :not(.rc-slider-disabled) {
    .rc-slider-handle {
      &:hover {
        filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.7)) drop-shadow(0px 2px 5px rgba(60, 66, 87, 0.1));
      }
    }
  }
}
